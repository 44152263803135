import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  BaseNewMatch,
  BasePauseMatch,
  BaseStopMatch,
  CheckCancelledMealsStep,
  CreateMealsInFutureMatchParams,
  DetailMatch,
  Match,
  MatchAutomaticUpdateParams,
  MatchFieldParams,
  MatchId,
  MatchMonitoringPageData,
  NewMatch,
  NewMatchCook,
  NewMatchParams,
  ExternalMatchContactMoment,
  PauseMatch,
  PauseMatchParams,
  PlanningSettingsParams,
  PreprocessStopMatchParams,
  PriceSettingsParams,
  SearchMatchParams,
  StopMatch,
  StopMatchParams,
  StopMatchStartData,
  TableMatch,
} from '/@src/types/matches'
import { MatchSortOption } from '/@src/types/matches'
import { cloneDeep } from 'lodash'
import type { ApiResponse, KebabCase } from '/@src/types/utils'
import { toast } from 'vue-sonner'
import { calculateCommissionFromMeal } from '/@src/utils/payments'
import { type DetailMatchRequest } from '/@src/types/matchRequests'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import { type CalendarMatchMeal, type MatchMealId } from '/@src/types/meals'
import { type SubjectHistoryResponse } from '/@src/types/events'
import { allowReadOnly } from '/@src/utils/axios-utils'
import type {
  ExtraContactMoment,
  FetchMatchContactDataResult,
  MatchContactMoment,
  MatchContactMomentAnswerParams,
  MatchContactMomentId,
} from '/@src/types/contact-moment'
import { ContactMoment } from '/@src/types/contact-moment'
import type { MaybeRef } from 'vue'
import type { AdminTask } from '/@src/types/admin-tasks'
import type { CookId } from '/@src/types/cooks'
import type { UserId } from '/@src/types/users'
import { undefinedUserId } from '/@src/models/users'
import type { Dayjs } from 'dayjs'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

const api = useApi()

interface State {
  searchParams: SearchMatchParams
  searchResults: TableMatch[]
  sortingParam: MatchSortOption

  specificMatch?: DetailMatch
  specificNewMatchCook?: NewMatchCook

  newMatch: NewMatch
  pauseMatch: PauseMatch
  stopMatch: StopMatch

  reportIds: MatchId[]
}

const defaultParams: SearchMatchParams = {
  query: '',
  addressQuery: {
    municipality: [],
    city: [],
    borough: [],
    neighborhood: [],
    district: [],
  },
  limit: 30,
  maxDaysOld: null,
  matchmakerId: undefinedUserId,
  impactAcceleratorId: undefinedUserId,
  sampleMealFilter: null,
  vogFilter: null,
  matchStatus: null,
  tags: [],
  filterOnPress: {
    cook: [],
    foodie: [],
  },
  filterOnAmbassador: {
    cook: [],
    foodie: [],
  },
  paymentMethod: null,
  paymentStatus: null,
}

const baseNewMatch: BaseNewMatch = {
  matchRequestId: undefined,
  cookId: undefined,
  step: 'general',
  generalInfoStep: {
    startDate: undefined,
    endDate: undefined,
    daysOfWeek: [],
    isFlexible: false,
    isSampleMeal: false,
    recurring: 1,
  },
  mealStep: {
    portions: 1,
    amountOfPeople: 1,
    price: undefined,
    transport: undefined,
  },
  paymentStep: {
    paymentMethodFoodie: undefined,
    paymentMethodCook: undefined,
    commissionType: undefined,
    isPrepaid: false,
  },
  paymentInfoStepFoodie: {
    isOverride: false,
  },
  paymentInfoStepCook: {
    isOverride: false,
  },
  finishedStep: {
    sendConfirmationMail: true,
  },
}

const basePauseMatch: BasePauseMatch = {
  id: undefined,
  step: 'general',
  generalStep: {
    pauseFrom: null,
    pauseUntil: null,
  },
  checkMealsStep: {
    correction: [],
    modifiable: [],
    futureNonCancelled: [],
  },
}

const baseStopMatch: BaseStopMatch = {
  id: undefined,
  step: 'general',
  generalStep: {
    endDate: undefined,
    stopReasonId: undefined,
    stopReasonText: undefined,
  },
  checkMealsStep: {
    correction: [],
    modifiable: [],
    futureNonCancelled: [],
  },
  paymentInfoFoodieStep: {
    paymentType: undefined,
    bankaccountName: undefined,
    bankaccountNumber: undefined,
    donationWalletComment: undefined,
  },
  paymentInfoCookStep: {
    paymentType: undefined,
    bankaccountName: undefined,
    bankaccountNumber: undefined,
    donationWalletComment: undefined,
  },
  finishedStep: {
    sendConfirmationMail: true,
  },
}

export const useMatchesStore = defineStore('matches', {
  state: (): State => ({
    searchParams: cloneDeep(defaultParams),
    searchResults: [],
    sortingParam: MatchSortOption.NEW_TO_OLD,

    specificMatch: undefined,
    specificNewMatchCook: undefined,

    newMatch: cloneDeep(baseNewMatch as NewMatch),
    pauseMatch: cloneDeep(basePauseMatch as PauseMatch),
    stopMatch: cloneDeep(baseStopMatch as StopMatch),

    reportIds: [],
  }),
  persist: usePersist<State>({
    pick: ['sortingParam', 'newMatch', 'pauseMatch', 'stopMatch', 'reportIds'],
  }),
  logout: {
    pick: ['sortingParam', 'searchParams'],
  },
  actions: {
    resetFilter() {
      this.searchParams = cloneDeep(defaultParams)
    },
    resetNewMatch() {
      this.newMatch = cloneDeep(baseNewMatch as NewMatch)
    },
    resetPauseMatch() {
      this.pauseMatch = cloneDeep(basePauseMatch as PauseMatch)
    },
    resetStopMatch() {
      this.stopMatch = cloneDeep(baseStopMatch as StopMatch)
    },
    setNewMatch(matchRequest: DetailMatchRequest) {
      if (this.newMatch.matchRequestId !== matchRequest.id) {
        this.resetNewMatch()
      }

      this.newMatch.matchRequestId = matchRequest.id

      this.newMatch.generalInfoStep.daysOfWeek = matchRequest.daysPreferred ?? []

      this.newMatch.mealStep.portions = matchRequest.portions ?? 1
      this.newMatch.mealStep.amountOfPeople = matchRequest.amountOfPeople ?? 1
      this.newMatch.mealStep.transport = matchRequest.transport

      const foodiePaymentInfo = matchRequest.foodie.paymentInformation
      if (foodiePaymentInfo.bankaccountName && foodiePaymentInfo.bankaccountNumber) {
        this.newMatch.paymentInfoStepFoodie = {
          isOverride: true,
          bankaccountName: foodiePaymentInfo.bankaccountName,
          bankaccountNumber: foodiePaymentInfo.bankaccountNumber,
        }
      } else if (foodiePaymentInfo.consentAt) {
        this.newMatch.paymentInfoStepFoodie.isOverride = false
      }
    },
    setNewCook(cook: NewMatchCook, matchRequest: DetailMatchRequest) {
      if (this.newMatch.cookId !== cook.id) {
        this.resetNewMatch()
        this.setNewMatch(matchRequest)
      }

      this.newMatch.cookId = cook.id

      if (
        cook.paymentInformation.bankaccountName &&
        cook.paymentInformation.bankaccountNumber
      ) {
        this.newMatch.paymentInfoStepCook = {
          isOverride: true,
          bankaccountName: cook.paymentInformation.bankaccountName,
          bankaccountNumber: cook.paymentInformation.bankaccountNumber,
        }
      } else if (cook.paymentInformation.consentAt) {
        this.newMatch.paymentInfoStepCook.isOverride = false
      }
    },

    setPauseMatch(matchRef: MaybeRef<Match>) {
      const match = toValue(matchRef)
      if (this.pauseMatch.id !== match.id) {
        this.resetPauseMatch()
        this.pauseMatch.id = match.id
      }
    },

    setStopMatch(matchRef: MaybeRef<DetailMatch>) {
      const match = toValue(matchRef)
      if (this.stopMatch.id !== match.id) {
        this.resetStopMatch()
        this.stopMatch.id = match.id
        this.stopMatch.paymentInfoCookStep = {
          paymentType: undefined as any, //Hack to not add a default value here
          donationWalletComment: null,
          bankaccountName: match.cook.paymentInformation.bankaccountName,
          bankaccountNumber: match.cook.paymentInformation.bankaccountNumber,
        }
        this.stopMatch.paymentInfoFoodieStep = {
          paymentType: undefined as any, //Hack to not add a default value here
          donationWalletComment: null,
          bankaccountName: match.foodie.paymentInformation.bankaccountName,
          bankaccountNumber: match.foodie.paymentInformation.bankaccountNumber,
        }
      }
    },

    async create() {
      return new Promise<number | false>((resolve) => {
        api.post<{ matchId: number }>(`admin/matches`, this.newMatchParams).then(
          (response) => {
            toast.success('Match maken gelukt!')
            resolve(response.data.matchId)
          },
          () => {
            toast.error('Match maken is mislukt.')
            resolve(false)
          },
        )
      })
    },

    async fetch(matchId: MatchId) {
      return new Promise<boolean>((resolve) => {
        api.get<ApiResponse<DetailMatch>>(`admin/matches/${matchId}`).then(
          (response) => {
            const json = response.data
            this.specificMatch = json.data
            resolve(true)
          },
          () => {
            toast.error('Kon match niet ophalen.')
            resolve(false)
          },
        )
      })
    },

    async fetchNewMatchCook(cookId: CookId) {
      return new Promise<boolean>((resolve) => {
        api.get<ApiResponse<NewMatchCook>>(`admin/cooks/${cookId}/new-match`).then(
          (response) => {
            const json = response.data
            this.specificNewMatchCook = json.data
            resolve(true)
          },
          () => {
            toast.error('Kon thuiskok niet ophalen.')
            resolve(false)
          },
        )
      })
    },

    async fetchMeals(matchId: MatchId) {
      return new Promise<false | CalendarMatchMeal[]>((resolve) => {
        api
          .get<ApiResponse<CalendarMatchMeal[]>>(`admin/matches/${matchId}/meals`)
          .then((res) => resolve(res.data.data))
          .catch(() => {
            toast.error('Fout bij ophalen maaltijden')
          })
      })
    },

    async fetchHistory(matchId: MatchId) {
      return new Promise<SubjectHistoryResponse<Match> | false>((resolve) => {
        api
          .get<SubjectHistoryResponse<Match>>(`admin/matches/${matchId}/history`)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },

    async fetchNextTask(matchId: MatchId) {
      return new Promise<AdminTask | null | false>((resolve) => {
        api
          .get<ApiResponse<AdminTask | null>>(`admin/matches/${matchId}/next-task`)
          .then((response) => {
            resolve(response.data.data)
          })
          .catch(() => {
            toast.error('Fout bij ophalen volgende herinnering')
            resolve(false)
          })
      })
    },

    async updateFieldAutomatically(payload: MatchAutomaticUpdateParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${payload.id}/update/automatic`, payload)
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet geüpdate.'))
      })
    },

    async updateField(payload: MatchFieldParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${payload.id}/update/${payload.field}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet geüpdate.'))
      })
    },

    async assignMatchContactMoment(
      matchId: MatchId,
      type: ContactMoment | `${number}-months`,
      userId?: UserId,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/assign-contact-moment`, {
            userId: userId,
            type: type,
          })
          .then(resolveWebSocketUpdate(resolve, 'Contactmoment toegewezen!'))
          .catch(rejectWebSocketUpdate(resolve, 'Contactmoment toewijzen mislukt.'))
      })
    },

    async saveMatchContactMoment(payload: MatchContactMomentAnswerParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${payload.id}/update/contact-moment`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Contactmoment opgeslagen!'))
          .catch(rejectWebSocketUpdate(resolve, 'Contactmoment opslaan mislukt.'))
      })
    },

    async saveMatchContactMomentComment(
      matchId: MatchId,
      type: KebabCase<ContactMoment> | ExtraContactMoment,
      comments: string,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/contact-moments/update-comments`, {
            type,
            comments,
          })
          .then(resolveWebSocketUpdate(resolve, 'Contactmoment opgeslagen!'))
          .catch(rejectWebSocketUpdate(resolve, 'Contactmoment opslaan mislukt.'))
      })
    },

    async createFuturePlannedMeals(
      matchId: MatchId,
      payload: CreateMealsInFutureMatchParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/create-future-meals`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Maaltijden aangemaakt'))
          .catch(rejectWebSocketUpdate(resolve, 'Maaltijden aanmaken mislukt'))
      })
    },

    async cancelMeals(
      matchId: MatchId,
      payload: { cancelFrom: Dayjs; mealIds: MatchMealId[] },
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/cancel-meals`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Maaltijden zijn geannuleerd'))
          .catch(rejectWebSocketUpdate(resolve, 'Maaltijden annuleren is mislukt'))
      })
    },

    async updatePriceSettings(payload: PriceSettingsParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${payload.id}/update/price`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet geüpdate.'))
      })
    },

    async updatePlanningSettings(payload: PlanningSettingsParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${payload.id}/update/time`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet geüpdate.'))
      })
    },

    async fetchStopMatchData(matchId: MatchId) {
      return new Promise<StopMatchStartData | false>((resolve) => {
        api
          .get<ApiResponse<StopMatchStartData>>(`admin/matches/${matchId}/stop`)
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Kon data niet ophalen')
            resolve(false)
          })
      })
    },

    async preprocessCancelMeals(payload: PreprocessStopMatchParams) {
      return new Promise<CheckCancelledMealsStep | false>((resolve) => {
        api
          .post<CheckCancelledMealsStep>(
            `admin/matches/${payload.id}/preprocess-cancel-meals`,
            payload,
          )
          .then((result) => {
            resolve(result.data)
          })
          .catch(() => {
            toast.error('Maaltijden voorberekenen mislukt')
            resolve(false)
          })
      })
    },

    async putPauseMatch() {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${this.pauseMatchParams.id}/pause`, this.pauseMatchParams)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol aangepast.'))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet gepauzeerd.'))
      })
    },

    async putStopMatch() {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${this.stopMatchParams.id}/stop`, this.stopMatchParams)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol aangepast.'))
          .catch((error) => {
            if (error.status === 'error') {
              toast.error(
                `Match gestopt, gebruiker niet toegevoegd aan betalingsbatch. Error: ${error.message}`,
              )
              resolve(false)
            } else {
              toast.error('Match stopzetten mislukt.')
              resolve(false)
            }
          })
      })
    },

    async unpauseMatch(matchId: MatchId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/unpause`)
          .then(resolveWebSocketUpdate(resolve, 'Match succesvol hervat.'))
          .catch(rejectWebSocketUpdate(resolve, 'Match niet hervat.'))
      })
    },

    async fixBrokenMeals(matchId: MatchId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/matches/${matchId}/fix-broken-meals`)
          .then(resolveWebSocketUpdate(resolve, 'Maaltijden zijn aangemaakt!'))
          .catch(rejectWebSocketUpdate(resolve, 'Maaltijden fixen is niet gelukt.'))
      })
    },

    async get(matchIds: MatchId[]) {
      return new Promise<TableMatch[] | false>((resolve) => {
        api
          .post<ApiResponse<TableMatch[]>>(
            `admin/matches/get`,
            {
              ids: matchIds,
            },
            allowReadOnly(),
          )
          .then(
            (response) => {
              const json = response.data
              resolve(json.data)
            },
            () => {
              toast.error('Matches ophalen mislukt.')
              resolve(false)
            },
          )
      })
    },

    async search() {
      return new Promise<boolean>((resolve) => {
        api
          .post<
            ApiResponse<TableMatch[]>
          >('admin/matches/search', this.searchParams, allowReadOnly())
          .then(
            (response) => {
              const json = response.data
              this.searchResults = json.data
              resolve(true)
            },
            () => {
              toast.error('Zoeken naar matches mislukt.')
              resolve(false)
            },
          )
      })
    },

    async getMatchesForMatchMonitoring(userId?: UserId) {
      return new Promise<MatchMonitoringPageData | false>((resolve) => {
        api
          .post<MatchMonitoringPageData>('admin/matches/match-monitoring', {
            userId: userId,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            toast.error('Matches ophalen mislukt')
            resolve(false)
          })
      })
    },

    async getMatchContactData(matchId: MatchId) {
      return new Promise<false | FetchMatchContactDataResult>((resolve) => {
        api
          .get<ApiResponse<FetchMatchContactDataResult>>(
            `admin/matches/${matchId}/contact-moments`,
          )
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {
            toast.error('Contact momenten konden niet opgehaald worden')
            resolve(false)
          })
      })
    },

    async getOrAssignContactMomentToExternal() {
      return new Promise<ExternalMatchContactMoment | false>((resolve) => {
        api
          .get<ApiResponse<ExternalMatchContactMoment>>('admin/external/contact-moments')
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {
            toast.error('Ophalen contactmoment mislukt')
            resolve(false)
          })
      })
    },

    async saveMatchContactMomentExternal(payload: MatchContactMomentAnswerParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/external/contact-moments/${payload.id}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Contactmoment opgeslagen!'))
          .catch(rejectWebSocketUpdate(resolve, 'Contactmoment opslaan mislukt.'))
      })
    },

    async createContactMomentTaskFromExternalUser(matchId: MatchId, message: string) {
      return new Promise<boolean>((resolve) => {
        api
          .post('admin/external/contact-moments/create-task', {
            matchId,
            message,
          })
          .then(() => {
            toast.success('Bericht verstuurd naar matchmakers')
            resolve(true)
          })
          .catch(() => {
            toast.error('Bericht opslaan mislukt')
            resolve(false)
          })
      })
    },

    async skipContactMomentTaskForExternalUser(
      matchId: MatchId,
      contactMomentId: MatchContactMomentId,
      message: string,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put('admin/external/contact-moments/skip', {
            matchId,
            contactMomentId,
            message,
          })
          .then(() => {
            toast.success('Contactmoment overgeslagen')
            resolve(true)
          })
          .catch(() => {
            toast.error('Contactmoment overslaan mislukt')
            resolve(false)
          })
      })
    },
  },
  getters: {
    match: (state): DetailMatch => state.specificMatch!,
    newMatchCook: (state): NewMatchCook => state.specificNewMatchCook!,
    newMatchParams: (state): NewMatchParams => {
      const newMatch = state.newMatch

      let price = newMatch.mealStep.price
      price += calculateCommissionFromMeal(newMatch.mealStep.price)

      return {
        matchRequestId: newMatch.matchRequestId,
        cookId: newMatch.cookId,
        startDate: newMatch.generalInfoStep.startDate,
        endDate: newMatch.generalInfoStep.endDate,
        recurring: newMatch.generalInfoStep.recurring,
        daysOfWeek: newMatch.generalInfoStep.daysOfWeek,
        transport: newMatch.mealStep.transport,
        portions: newMatch.mealStep.portions,
        amountOfPeople: newMatch.mealStep.amountOfPeople,
        price: price,
        paymentMethodFoodie: newMatch.paymentStep.paymentMethodFoodie,
        paymentMethodCook: newMatch.paymentStep.paymentMethodCook,
        commissionType: newMatch.paymentStep.commissionType,
        isPrepaid: newMatch.paymentStep.isPrepaid ?? false,
        isSampleMeal: newMatch.generalInfoStep.isSampleMeal,
        isFlexible: newMatch.generalInfoStep.isFlexible ?? false,
        sendConfirmationMail: newMatch.finishedStep.sendConfirmationMail ?? false,
        foodiePaymentInfo: newMatch.paymentInfoStepFoodie,
        cookPaymentInfo: newMatch.paymentInfoStepCook,
      }
    },

    pauseMatchParams: (state): PauseMatchParams => {
      const pauseMatch = state.pauseMatch

      return {
        id: pauseMatch.id,
        pauseFrom: pauseMatch.generalStep.pauseFrom,
        pauseUntil: pauseMatch.generalStep.pauseUntil,
      }
    },

    stopMatchParams: (state): StopMatchParams => {
      const stopMatch = state.stopMatch

      const cookPaymentInfo = stopMatch.paymentInfoCookStep.paymentType
        ? stopMatch.paymentInfoCookStep
        : undefined
      const foodiePaymentInfo = stopMatch.paymentInfoFoodieStep.paymentType
        ? stopMatch.paymentInfoFoodieStep
        : undefined

      return {
        id: stopMatch.id,

        endDate: stopMatch.generalStep.endDate,
        cookComments: stopMatch.generalStep.cookComments,
        stopReasonId: stopMatch.generalStep.stopReasonId,
        stopReasonText: stopMatch.generalStep.stopReasonText,

        sendConfirmationMail: stopMatch.finishedStep.sendConfirmationMail,

        foodie: foodiePaymentInfo,
        cook: cookPaymentInfo,
      }
    },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMatchesStore, import.meta.hot))
}
