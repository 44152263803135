import dayjs, { Dayjs, type OpUnitType } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import objectSupport from 'dayjs/plugin/objectSupport'
import type { BareBonesDayjs } from '/@src/types/utils'
import type { MaybeRefOrGetter } from 'vue'

dayjs.extend(timezone)
dayjs.extend(objectSupport)

export function dayjsUTC(): Dayjs {
  return dayjs.utc()
}

interface DateOverlapParam {
  from: Dayjs
  to: Dayjs
}
export function datePeriodsOverlap(
  periodA: DateOverlapParam,
  periodB: DateOverlapParam,
  type: OpUnitType = 'day',
) {
  return (
    periodA.from.isSameOrBefore(periodB.to, type) &&
    periodA.to.isSameOrAfter(periodB.from, type)
  )
}

export function formatDate(
  dateRef: MaybeRefOrGetter<Dayjs | null | undefined>,
): undefined | string {
  const date = toValue(dateRef)
  if (!date) {
    return undefined
  }

  // Even though the type _should_ always be Dayjs, it is possible
  // that this function is called before the stores are transformed
  // The extra `dayjs()` call guarantees that the variable is always dayjs :)
  return dayjs(date).format('DD-MM-YYYY')
}

export function formatDateAndTime(
  dateRef: MaybeRefOrGetter<Dayjs | null | undefined>,
): undefined | string {
  const date = toValue(dateRef)
  if (!date) {
    return undefined
  }
  return dayjs(date).format('DD-MM-YYYY HH:mm')
}

export function formatAge(
  dateRef: MaybeRefOrGetter<Dayjs | null | undefined>,
): undefined | string {
  const date = toValue(dateRef)
  if (!date) {
    return undefined
  }

  return `${dayjsUTC().diff(date, 'year')}`
}

export function formatDateAndAge(
  dateRef: MaybeRefOrGetter<Dayjs | null | undefined>,
): undefined | string {
  const date = toValue(dateRef)
  if (!date) {
    return undefined
  }

  return `${dayjs(date).format('DD-MM-YYYY')} (${dayjsUTC().diff(date, 'year')})`
}

export function formatTime(dateRef: MaybeRefOrGetter<Dayjs>) {
  const date = toValue(dateRef)
  return date.tz('Europe/Amsterdam').format('HH:mm')
}

export function formatLocalizedDate(dateRef: MaybeRefOrGetter<Dayjs>) {
  const date = toValue(dateRef)
  if (date.year() !== dayjsUTC().year()) {
    return dayjs(date).format('D MMMM YYYY')
  }

  return dayjs(date).format('D MMMM')
}

// time is in seconds
export function getTimeDuration(time?: number) {
  if (!time) {
    return '-'
  }

  const hours = time / 60 / 60

  if (hours < 48) {
    return `${hours.toFixed(2)} uur`
  } else {
    const days = hours / 24
    return `${days.toFixed(2)} dag`
  }
}

export function formatMonthlyTarget(target: { year: number; month: number }): string {
  return `${dayjsUTC()
    .month(target.month - 1)
    .format('MMMM')} ${target.year}`
}

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/
const simpleDateFormat = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

function isSimpleDateString(value: any): boolean {
  return value && typeof value === 'string' && simpleDateFormat.test(value)
}

export function parseDateResponse(body: any, internalCount = 0) {
  if (
    body === null ||
    body === undefined ||
    typeof body !== 'object' ||
    internalCount > 10
  )
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) {
      body[key] = dayjs.utc(value)
    } else if (isSimpleDateString(value)) {
      body[key] = dayjs(value)
    } else if (typeof value === 'object') {
      parseDateResponse(value, internalCount + 1)
    }
  }
}

export function parseDateRequest(body: any, internalCount = 0) {
  if (
    body === null ||
    body === undefined ||
    typeof body !== 'object' ||
    internalCount > 10
  )
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (dayjs.isDayjs(value)) {
      body[key] = value.tz('Etc/UTC', true).toISOString()
    } else if (typeof value === 'object') {
      parseDateRequest(value, internalCount + 1)
    }
  }
}

export function parseBareBonesDayjs(
  bareBonesDayJs?: BareBonesDayjs | null,
): Dayjs | null {
  if (!bareBonesDayJs) {
    return null
  }
  return dayjs({
    year: bareBonesDayJs.$y,
    month: bareBonesDayJs.$M,
    day: bareBonesDayJs.$D,
    hour: bareBonesDayJs.$H,
    minute: bareBonesDayJs.$m,
    second: bareBonesDayJs.$s,
    millisecond: bareBonesDayJs.$ms,
  }).tz('Etc/UTC')
}
