<script setup lang="ts">
import type { TotalMonthlyTargets } from '/@src/types/monthly-targets'

interface TargetAndValueRecord<T> {
  bronze: T
  silver: T
  gold: T
  value: T
}

interface GlobalTargetBarProps {
  targets: TotalMonthlyTargets
  value: number
  disableTargets?: Partial<TargetAndValueRecord<boolean>>
  texts: TargetAndValueRecord<string>
  bottom: 0 | `${number}rem`
}

const props = defineProps<GlobalTargetBarProps>()

const fullBarAmount = computed<number>(() => {
  if (!props.disableTargets?.gold) {
    return props.targets.goldValue * 1.1
  } else if (!props.disableTargets?.silver) {
    return props.targets.silverValue * 1.1
  } else if (!props.disableTargets?.bronze) {
    return props.targets.bronzeValue * 1.1
  } else {
    return props.value
  }
})

const valueRatio = computed(() => props.value / fullBarAmount.value)

const bronzeRatio = computed(() => {
  if (props.targets.bronzeValue === 0) {
    return 0.7
  } else {
    return props.targets.bronzeValue / fullBarAmount.value
  }
})
const silverRatio = computed(() => {
  if (props.targets.silverValue === 0) {
    return 0.7 * 1.19
  } else {
    return props.targets.silverValue / fullBarAmount.value
  }
})
const goldRatio = computed(() => {
  if (props.targets.goldValue === 0) {
    return 0.7 * 1.33
  } else {
    return props.targets.goldValue / fullBarAmount.value
  }
})

const valueBarColour = computed(() => {
  const newMatchesCount = props.value
  let color

  if (!newMatchesCount || newMatchesCount < props.targets.bronzeValue) {
    color = 'danger'
  } else if (newMatchesCount < props.targets.silverValue) {
    color = 'bronze'
  } else if (newMatchesCount < props.targets.goldValue) {
    if (props.disableTargets?.silver) {
      color = 'bronze'
    } else {
      color = 'silver'
    }
  } else {
    color = 'gold'
  }

  return `has-background-${color}`
})

const bronzeBarColour = computed(() => {
  if (props.targets.bronzeValue === 0 || props.value < props.targets.bronzeValue) {
    return 'has-background-grey'
  } else {
    return 'has-background-bronze'
  }
})
</script>

<template>
  <div class="global-bar has-background-grey" :style="{ bottom: bottom }">
    <div
      v-if="!disableTargets?.bronze"
      v-tooltip.bottom-end="
        texts.bronze.replace('{value}', targets.bronzeValue.toFixed(0))
      "
      class="bronze-bar"
      :class="bronzeBarColour"
      :style="{ width: `${bronzeRatio * 100}%` }"
    />
    <div
      v-if="!disableTargets?.silver"
      v-tooltip.bottom-end="
        texts.silver.replace('{value}', targets.silverValue.toFixed(0))
      "
      class="silver-bar"
      :style="{
        width: `${(silverRatio - bronzeRatio) * 100}%`,
        left: `${bronzeRatio * 100}%`,
      }"
    />
    <div
      v-if="!disableTargets?.gold"
      v-tooltip.bottom-end="texts.gold.replace('{value}', targets.goldValue.toFixed(0))"
      class="gold-bar"
      :style="{
        width: `${(goldRatio - silverRatio) * 100}%`,
        left: `${silverRatio * 100}%`,
      }"
    />

    <div
      v-tooltip.bottom-end="texts.value.replace('{value}', value.toFixed(0))"
      class="value-bar"
      :class="valueBarColour"
      :style="{ width: `${valueRatio * 100}%` }"
    />
  </div>
</template>

<style scoped lang="scss">
%bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.2rem;
  outline: 0.05rem solid var(--dark-sidebar-dark-2);
}

%border {
  border-right: 2px solid black;
}

.global-bar {
  @extend %bar;

  width: 100vw;

  .bronze-bar {
    @extend %bar;
    @extend %border;

    z-index: 6;
  }

  .silver-bar {
    @extend %bar;
    @extend %border;

    z-index: 4;
  }

  .gold-bar {
    @extend %bar;
    @extend %border;

    z-index: 2;
  }

  .value-bar {
    @extend %bar;
    @extend %border;

    background-color: white;
    z-index: 7;
  }
}
</style>
